import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { ApiRequestService } from 'src/app/services/api-request.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NotificationType, NotificationsService } from 'angular2-notifications';
import { HttpClient } from '@angular/common/http';
import { map, startWith } from 'rxjs/operators';
import { DatePipe } from '@angular/common';

export interface Curso {
  id: number;
  name: string;
  slug: string;
  image: string;
  code: string;
  sigla: string;
  created_at: string;
  updated_at: string;
  forma_pagamento: string;
  status: string;
  disponivel_para_venda: string;
  acessar_biblioteca_virtual: boolean;
  codigo_curso_emec: string;
  nome_habilitacao_titulo_conferido: string;
  grau_conferido: string;
  logradouro: string;
  bairro: string;
  cep: string;
  cidade: string;
  city: object;
  codigo_municipio: string;
  estado: string;
  tipo_autorizacao: string;
  numero_autorizacao: string;
  data_autorizacao: string;
  tipo_reconhecimento: string;
  numero_reconhecimento: string;
  data_reconhecimento: string;
}

interface Cidade {
	id:number;
	name: string;
}
interface Estado {
	id:number;
	name: string;
	sigla: string;
}

export interface PaymentPlan {
  tipo: string;
  parcelas: string;
  valor_parcelas: string;
  total: string;
}

export interface HttpPostResponse {
  data: Curso;
  message: string;
}

@Component({
  selector: 'app-cadastrar-cursos',
  templateUrl: './cadastrar-cursos.component.html',
  styleUrls: ['./cadastrar-cursos.component.scss'],
  providers: [DatePipe]
})
export class CadastrarCursosComponent implements OnInit {
  form: FormGroup = this.formBuilder.group({
    name: ['', Validators.required],
    slug: ['', Validators.required],
    image: ['', Validators.required],
    sigla: ['', Validators.required],
    code: ['', Validators.required],
    status: ['', Validators.required],
    disponivel_para_venda: ['', Validators.required],
    acessar_biblioteca_virtual: [false, Validators.required],
    id: [null],
    codigo_curso_emec: ['', Validators.required],
    nome_habilitacao_titulo_conferido: ['', Validators.required],
    grau_conferido: ['', Validators.required],
    logradouro: ['', Validators.required],
    bairro: ['', Validators.required],
    cep: ['', Validators.required],
    cidade: ['', Validators.required],
    codigo_municipio: ['', Validators.required],
    estado: ['', Validators.required],
    tipo_autorizacao: ['', Validators.required],
    numero_autorizacao: ['', Validators.required],
    data_autorizacao: ['', Validators.required],
    tipo_reconhecimento: ['', Validators.required],
    numero_reconhecimento: ['', Validators.required],
    data_reconhecimento: ['', Validators.required],
  });

  formPayment: FormGroup = this.formBuilder.group({
    tipo: ['', Validators.required],
    parcelas: ['', Validators.required],
    valor_parcelas: ['', Validators.required],
    valor_antecipado_parcelas: ['', Validators.required],
    total: ['', Validators.required],
  });

  curso: Curso;
  columnsToDisplay = ["name", "acoes"];
  id: number;
  isLoading = false;
  isSaveLoading = false;
  imagePreview: string;
  addPaymentPlan = false;
  paymentPlans: PaymentPlan[] = [];
  isAvailable = false;
  isActive = false;
	cepData:any;
  estados:any = [];
	cidades:any = [];
	filteredCidades:any;
	filteredEstados:any;

	constructor(
		private router: Router,
    private api: ApiRequestService,
    private activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private notifications: NotificationsService,
		private http: HttpClient,
    private datePipe: DatePipe

	) { }

	async ngOnInit() {
    await this.CarregarEstados();
		this.setupAutoCompleteEstados();
    this.activatedRoute.params.subscribe(params => {
      if (params['id']) {
        this.id = params['id'];
        this.getCursoById();
      }
    });

	}

  getCursoById() {
    const url = `cursos/${this.id}`;
    this.isLoading = true;

    this.api.Get(url).subscribe(({
      next: (res: any) => {
        this.curso = res.data;
        this.preencheDadosFormulario(res.data);
        this.isLoading = false;
      },
      error: (err) => {
        console.log(err);
        this.isLoading = false;
      }
    }));
  }

  onFileChanged(event) {
		let f:File = event.target.files[0];

    const reader = new FileReader();

    reader.onload = (e: any) => {
      this.imagePreview = e.target.result;
    };

    reader.readAsDataURL(f);

		this.api.UploadPosS3(f).subscribe((res:any)=>{
      this.form.get('image').setValue(res.file);
		});
	}

	//UPLOAD DE ARQUIVOS
	onFileChangedS3(event,) {
		let f:File = event.target.files[0];

		this.api.UploadS3(f).then((ret:any)=>{
			console.log(ret);
		});
	}

  showAddPaymentSection() {
    this.addPaymentPlan = true;
  }

  onSubmit() {
    if (this.curso) {
      this.update();
    } else {
      this.save();
    }
  }

  save() {
    const url = `cursos/create`;
    this.isSaveLoading = true;
    const data = {
      ...this.form.value,
      forma_pagamento: JSON.stringify(this.paymentPlans)
    }

    this.api.Post(url, data).subscribe(({
      next: (res: HttpPostResponse) => {
        this.isSaveLoading = false;
        this.notifications.create("Sucesso!", res.message, NotificationType.Success, { timeOut: 2500 } );

        setTimeout(() => {
          this.router.navigate(['/cursos']);
        }, 1000);
      },
      error: (err) => {
        console.log(err);
      }
    }));
  }

  update() {
    const url = `cursos/update`;
    this.isSaveLoading = true;
    this.form.get('id').setValue(this.id);

    const data = {
      ...this.form.value,
      forma_pagamento: JSON.stringify(this.paymentPlans)
    };

    data.data_reconhecimento = this.datePipe.transform(data.data_reconhecimento, 'yyyy-MM-dd');
    data.data_autorizacao = this.datePipe.transform(data.data_autorizacao, 'yyyy-MM-dd');
    data.cidade = data.cidade.id;
    data.estado = data.estado.sigla;

    this.api.Post(url, data).subscribe(({
      next: (res: HttpPostResponse) => {
        console.log(res);
        this.isSaveLoading = false;
        this.notifications.create("Sucesso!", res.message, NotificationType.Success, { timeOut: 2500 } );

        setTimeout(() => {
          this.router.navigate(['/cursos']);
        }, 1000);
      },
      error: (err) => {
        console.log(err);
      }
    }));
  }

  preencheDadosFormulario(data: Curso) {


    const {
      name,
      slug,
      image,
      sigla,
      code,
      id,
      forma_pagamento,
      status,
      disponivel_para_venda,
      acessar_biblioteca_virtual,
      codigo_curso_emec,
      nome_habilitacao_titulo_conferido,
      grau_conferido,
      logradouro,
      bairro,
      cep,
      cidade,
      codigo_municipio,
      estado,
      tipo_autorizacao,
      numero_autorizacao,
      data_autorizacao,
      tipo_reconhecimento,
      numero_reconhecimento,
      data_reconhecimento,
    } = data;

    this.paymentPlans = JSON.parse(forma_pagamento);
    status == 'ativo' ? this.isActive = true : this.isActive = false;
    disponivel_para_venda == 'sim' ? this.isAvailable = true : this.isAvailable = false;
    this.form.patchValue({
      name,
      slug,
      sigla,
      code,
      image,
      id,
      status,
      disponivel_para_venda,
      acessar_biblioteca_virtual,
      codigo_curso_emec,
      nome_habilitacao_titulo_conferido,
      grau_conferido,
      logradouro,
      bairro,
      cep,
      cidade: data.city,
      codigo_municipio,
      // estado,
      tipo_autorizacao,
      numero_autorizacao,
      data_autorizacao: new Date(data_autorizacao),
      tipo_reconhecimento,
      numero_reconhecimento,
      data_reconhecimento: new Date(data_reconhecimento),
    });

    this.setEstado(data.estado);
  }

  GetField(field:string){
		return this.form.get(field);
	}

  GetError(field:string){
		if(this.form.get(field).hasError('required')){
			return "Este campo é obrigatório";
		}
  }

  savePaymentPlan() {
    this.paymentPlans = [...this.paymentPlans, this.formPayment.value];
    this.notifications.create("Sucesso!", 'Forma de pagamento adicionado com sucesso!', NotificationType.Success, { timeOut: 1500 } );
    this.formPayment.reset();
  }

  changeStatus(event: any) {
    if (event.checked) {
      this.form.get('status').setValue('ativo');
    } else {
      this.form.get('status').setValue('inativo');
    }
  }

  changeDisponivelParaVenda(event: any) {
    if (event.checked) {
      this.form.get('disponivel_para_venda').setValue('sim');
    } else {
      this.form.get('disponivel_para_venda').setValue('nao');
    }
  }

  checkTypePayment(event: any) {
    if (event.value == 'cartao a vista' || event.value == 'pix' || event.value == 'boleto a vista') {
      this.formPayment.get('parcelas').setValue('1');
    } else {
      this.formPayment.get('parcelas').setValue('');
    }
  }

  calcTotal() {
    const parcelas = +this.formPayment.get('parcelas').value;
    const valorParcelas = +this.formPayment.get('valor_parcelas').value;
    const total = parcelas * valorParcelas;

    this.formPayment.get('total').setValue(`${total}`);
  }

  paymentType(tipo: string){
    if (tipo=="invoice"){
      return "Boleto";
    }
    if (tipo=="once_credit_card"){
      return "Cartão 1x";
    }
    if (tipo=="installments_credit_card"){
      return "Cartão parcelado";
    }
    if (tipo=="recurrence"){
      return "Recorrência";
    }
    if (tipo=="pix"){
      return "Pix";
    }
  }


  async buscaCep(cep){

		cep = cep.replace("-","")

		if (cep.length == 8){
			this.http.get<any>(`https://viacep.com.br/ws/${cep}/json/`).subscribe(
				async (response) => {
					this.cepData = response;
					await this.setEstado(this.cepData.uf);
					await this.CarregarCidades(this.cepData.uf);
					await this.setCidade(this.cepData.localidade);
					this.form.get('bairro').setValue(this.cepData.bairro);
					this.form.get('logradouro').setValue(this.cepData.logradouro);


				},
				(error) => {
					console.log("Erro ao buscar: ", cep);

				}
			);
		}

	}

  async CarregarEstados(){

		const response = await this.api.Get("../estados").toPromise();
		this.estados = response;

	}

  async setEstado(uf: string){
		await this.estados.map((estado)=>{
			if (estado.sigla == uf){
				this.form.get('estado').setValue(estado);
			}
		})
	}

	async setCidade(localidade: string){

		if (this.cidades.length > 0){
			await this.cidades.map((cidade)=>{
				if (cidade.name == this.cepData.localidade){
					this.form.get('cidade').setValue(cidade);
				}
			});
		}else{
			console.error("Erro ao definir cidade");

    }
  }


	async CarregarCidades(uf: string){

		const response = await this.api.Get("../cidades/" + uf).toPromise();
		this.cidades = response;

		this.setupAutoCompleteCidades();

	}

  setupAutoCompleteEstados(){

		this.filteredEstados = this.form.get('estado').valueChanges
			.pipe(
				startWith<string | Estado>(''),
				map(value => typeof value === 'string' ? value : value.name),
				map(name => name ? this._filter(name, 'estado') : this.estados.slice())

			);

	}

	setupAutoCompleteCidades(){

		this.filteredCidades = this.form.get('cidade').valueChanges
			.pipe(
				startWith<string | Cidade>(''),
				map(value => typeof value === 'string' ? value : value.name),
				map(name => name ? this._filter(name, 'cidade') : this.cidades.slice())

			);
	}

  private _filter(value: string, tipo:string): any[] {

		const filterValue = value.toLowerCase();

		if(tipo == 'estado' && this.estados != undefined){

			return this.estados.filter(estado => estado.name.toLowerCase().includes(filterValue));

		}else if(tipo == 'cidade' && this.cidades != undefined){
			console.log("filterValue", filterValue);

			return this.cidades.filter(cidade => cidade.name.toLowerCase().includes(filterValue));
		}
	}


	displayFn(subject){
		return subject ? subject.name : "";
	}
}