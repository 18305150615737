import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { ApiRequestService } from '../../services/api-request.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'edit-couse-realization-date',
  templateUrl: './edit-couse-realization-date.component.html',
  styleUrls: ['./edit-couse-realization-date.component.scss'],
  providers: [DatePipe]
})
export class EditCourseRealizationDateComponent {
	start_at:Date;
	end_at:Date;

	constructor(
		private api:ApiRequestService,
		public dialogRef: MatDialogRef<EditCourseRealizationDateComponent>,
		@Inject(MAT_DIALOG_DATA) public dialogData: any,
		private datePipe: DatePipe

	) {
		this.start_at = dialogData.start_at;
		this.end_at = dialogData.end_at;
	}

	close(param: string) {
		let data;

		if (param === 'Cancelar') {
			this.dialogRef.close();
		} else {
			if (!!this.start_at && !!this.end_at){
				data = {
					"start_at":this.start_at,
					"end_at":this.end_at
				};
				this.dialogRef.close(data);
			}
		}


	}
}