import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { ApiRequestService } from "../../../services/api-request.service";
import {
  FormControl,
  FormGroup,
  Validators
} from "@angular/forms";

@Component({
  selector: "app-users-add-manual-payment",
  templateUrl: "./users-add-manual-payment.component.html",
})
export class UsersAddManualPaymentComponent {
  aluno: any
  userSession: any
  plan: any
  value = new FormControl({ value: "", disabled: true });
  paymentDate = new FormControl("", [Validators.required]);
  orderId = new FormControl("");
  transactionId = new FormControl("");
  installmentInfo = null;
  paymentForm: FormGroup = new FormGroup({
    value: this.value,
    paymentDate: this.paymentDate,
    orderId: this.orderId,
    transactionId: this.transactionId
  });
  maxDate: Date = new Date();
  saving = false;

  constructor(
    private api: ApiRequestService,
    public dialogRef: MatDialogRef<UsersAddManualPaymentComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.aluno = data.user
    this.value.setValue(data.installment.valor)
    this.installmentInfo = data.installment
    this.orderId.setValue(data.installment.order_id);
    this.transactionId.setValue(data.installment.transaction_id);
    let sessionData = JSON.parse(localStorage.getItem('userSession'));
    this.userSession = sessionData.user;
  }

  addManualPayment(): void {
    this.api.Post('payments', {
      installment_id: this.installmentInfo.id,
      paymentDate: this.paymentDate.value,
      orderId: this.orderId.value,
      transactionId: this.transactionId.value,
      userSessionId: this.userSession.id
    }).subscribe({
      next: (res: any) => {
        console.log("Pagamento enviado com sucesso!", res);
        res.user_id = this.aluno.id;
        this.dialogRef.close(res);
      },
      error: (err) => {
        console.error("Erro ao enviar pagamento:", err);
      }
    });
  }

  close(): void {
    this.dialogRef.close();
  }
}
