import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { ApiRequestService } from "../../../services/api-request.service";
import {
  FormControl,
  FormGroup,
  Validators
} from "@angular/forms";
import { debounceTime, distinctUntilChanged, tap } from "rxjs/operators";

@Component({
  selector: "app-users-add-plan",
  templateUrl: "./users-add-plan.component.html",
})
export class UsersAddPlanComponent {
  plans: any;

  user_id = ''
  plan_id = new FormControl(7, Validators.required);
  voucher = new FormControl("");
  validVoucher = new FormControl(true, Validators.requiredTrue);

  planForm: FormGroup = new FormGroup({
    plan_id: this.plan_id,
    voucher: this.voucher,
    validVoucher: this.validVoucher,
  });

  saving = false;

  constructor(
    private api: ApiRequestService,
    public dialogRef: MatDialogRef<UsersAddPlanComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.user_id = data.user.id

    this.api.Get("products").subscribe((res) => {
      this.plans = res;
      console.log(res);
    });

    this.voucher.valueChanges
      .pipe(
        tap(() => this.validVoucher.setValue(false)),
        debounceTime(800),
        distinctUntilChanged()
      )
      .subscribe((val) => this.validateVoucher(val));
  }

  validateVoucher(voucher: string): void {
    if (voucher.length === 0) {
      this.validVoucher.setValue(true);
    } else {
      this.api.Get(`validateVoucher/${voucher}`).subscribe(
        (res) => this.validVoucher.setValue(true),
        (err) => this.validVoucher.setValue(false)
      );
    }
  }

  addPlan(): void {
    this.saving = true;
    this.api.Post(`user/${this.user_id}/plan/${this.plan_id.value}`, {
      voucher: this.voucher.value
    }).subscribe(res => {
      this.dialogRef.close(res)
    }, ((res:any)=>{
      this.saving = false;
      alert(res.error.error);
    }))
  }

  Close(): void {
    this.dialogRef.close();
  }
}
