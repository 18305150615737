// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
	// production: false,
	production: true,
  	awsS3: {
		url: 'https://files.simsave.com.br/',
		region: 'us-east-2',
		bucketName: 'simsave',
		IdentityPoolId: 'us-east-2:70cbb92c-4134-4751-b325-0fb6c932bcaa',
	},
	// apiUrl: "http://localhost:8001/v1/admin/",
	// eadUrl: "http://localhost:4203/login/",
  // apiUrl: "https://posapibeta.faculdadeterzius.edu.br/v1/admin/",
	// eadUrl: "https://posbeta.faculdadeterzius.edu.br/login/"
  apiUrl: "https://posapi.faculdadeterzius.edu.br/v1/admin/",
	eadUrl: "https://pos.faculdadeterzius.edu.br/login/"
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
