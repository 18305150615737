import { Component, OnInit } from '@angular/core';
import { ApiRequestService } from '../../../services/api-request.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationsService, NotificationType } from 'angular2-notifications';
import { CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import { MatDialog } from '@angular/material/dialog';
import { JourneyStepSearchComponent } from "../../../dialogs/journey-step-search/journey-step-search.component";
import { EditJourneyStepDateComponent } from 'src/app/dialogs/edit-journey-step-date/edit-journey-step-date.component';
import { DatePipe } from '@angular/common';
import { PreviewContentComponent } from 'src/app/dialogs/preview-content/preview-content.component';

interface ModalInfo {
    class_content: string;
    title: string;
    instructor: string;
    short_description: string;
    [key: string]: any;
}
@Component({
  selector: 'app-journey-steps',
  templateUrl: './journey-steps.component.html',
  styleUrls: ['./journey-steps.component.scss'],
  providers: [DatePipe]
})
export class JourneyStepsComponent implements OnInit {

	journey_id:number;
	journey:any;

	constructor(
		public dialog: MatDialog,
		private route: ActivatedRoute,
		private api:ApiRequestService,
		private _notifications: NotificationsService,
		private datePipe: DatePipe
	) { }

	ngOnInit() {
		this.route.params.subscribe(ret => {
			this.journey_id = ret.id;

			this.LoadJourneyData();
		});
	}

	dropItem(list:any, event: CdkDragDrop<any[]>) {
		moveItemInArray(list, event.previousIndex, event.currentIndex);
		this.SaveOrder();
	}

	SaveOrder(){
		let titles:any[] = [];
		let steps:any[] = [];

		let iTitle = 0;

		this.journey.titles.forEach(title=> {
			titles.push({
				id: title.id,
				order: iTitle
			})

			let iStep = 0;
			title.steps.forEach(step=> {
				steps.push({
					id: step.id,
					order: iStep
				})
				iStep++;
			})

			iTitle++;
		});

		this.api.Post("journey/"+this.journey_id+"/order", {titles: titles, steps: steps}).subscribe((res:any)=>{
			this._notifications.success("Sucesso!", "Ordem alterada com sucesso", { timeOut: 5000 });
		})
	}

	NewStep(){
		
		let name = prompt("Digite no nome da etapa");
		if(!name) return;

		this.api.Post("journey/"+this.journey_id+"/title", {name: name}).subscribe((res:any)=>{
			this._notifications.success("Sucesso!", "Título criado com sucesso", { timeOut: 5000 });
			this.LoadJourneyData();
		})
		
	}

	EditStep(data:any){

		let name = prompt("Digite no nome da etapa", data.name);
		if(!name) return;

		data.name = name;

		this.api.Post("journey/"+this.journey_id+"/title/"+data.id, {name: name}).subscribe((res:any)=>{
			this._notifications.success("Sucesso!", "Título editado com sucesso", { timeOut: 5000 });
		})

	}

	RemoveStep(data:any){

		if(!confirm("Tem certeza que deseja remover este título? Todas as etapas desse item serão removidos.")) return;

		this.api.Delete("journey/"+this.journey_id+"/title/"+data.id).subscribe((res:any)=>{
			this._notifications.success("Sucesso!", "Título removido com sucesso", { timeOut: 5000 });
			this.LoadJourneyData();
		})

	}

	LoadJourneyData() {
		this.api.Get("journey/"+this.journey_id).subscribe((res:any)=>{
			this.journey = res;
		})
	}

	OpenStepModal(titleData:any){

		const dialogRef = this.dialog.open(JourneyStepSearchComponent, {
			width: '600px',
			data: {
				types: 'contents,simulations,quizzes'
			}
		});

		dialogRef.afterClosed().subscribe(result => {
			if(result) {
				this.api.Post("journey/"+this.journey_id+"/title/"+titleData.id+"/step", result).subscribe((res:any)=>{
					this._notifications.success("Sucesso!", "Nova etapa adicionada com sucesso", { timeOut: 5000 });
					this.LoadJourneyData();
				})
			}
		});

	}

	RemoveStepItem(title:any, step:any){

		if(!confirm("Tem certeza que deseja remover este item?")) return;

		this.api.Delete("journey/"+this.journey_id+"/title/"+title.id+"/step/"+step.id).subscribe((res:any)=>{
			this._notifications.success("Sucesso!", "Item removido com sucesso", { timeOut: 5000 });
			this.LoadJourneyData();
		})


	}

	editaData(title:any, step:any) {
		console.log("title", title);
		console.log("step", step);
		let available_at;
		let expires_at;
		
		const dialogRef = this.dialog.open(EditJourneyStepDateComponent, {
		  width: '600px',
		  height: '250px',
		  data: {
				available_at: step.available_at != null ? new Date(step.available_at) : null,
				expires_at: step.available_at != null ? new Date(step.expires_at) : null
        	}
		});
	
		dialogRef.afterClosed().subscribe((response) => {
			
		  if (!!response) {

			if (response.available_at > response.expires_at){
				this._notifications.create("Erro!", "A data final deve ser maior ou igual a data inicial", NotificationType.Error, { timeOut: 5000 } );
				return;
			}

			if (response.available_at==null || response.expires_at==null){
				available_at = null;
				expires_at = null;

			}else{
				response.available_at.setHours(0, 0, 0, 0);
				response.expires_at.setHours(23, 59, 59, 999);
				available_at = this.datePipe.transform(response.available_at, 'yyyy-MM-dd HH:mm:ss');
				expires_at = this.datePipe.transform(response.expires_at, 'yyyy-MM-dd HH:mm:ss');
			}
			this.api.Post("journey/"+step.id+"/changeDates", {available_at: available_at, expires_at: expires_at}).subscribe((res:any)=>{
				this._notifications.success("Sucesso!", "Período alterado com sucesso", { timeOut: 5000 });
				this.LoadJourneyData();

			})

		  }
		});
	}

	previewContent(step:any) {

		let modal_info: ModalInfo = {
			class_content: '',
			title: '',
			instructor: '',
			short_description: ''
		};

		this.journey.contents.forEach((content)=> {
			if(content.id==step.content_id){
				console.log("content",  content);
				
				if (content.videos.length > 0) {
					modal_info.video = content.videos[0];
				}

				modal_info.content_id = content.id;
				modal_info.class_content = content.class_content ? content.class_content : null;
				modal_info.title = content.title ? content.title : null;
				modal_info.instructor = content.instructor.name ? content.instructor.name : null;
				modal_info.short_description = content.short_description ? content.short_description : null;
			}
		});

		
		const dialogRef = this.dialog.open(PreviewContentComponent, {
		  width: '800px',
		  height: '700px',
		  data: modal_info
		});
	
		dialogRef.afterClosed().subscribe((response) => {
		});
	}

}
